import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Paper} from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';

import logo from 'static/images/logo.png'
import * as axios from 'lib/api/login'
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {GOOGLE_CLIENT_ID, LOCAL_BATCH} from "../../../constants";

const styles = theme => ({
  main: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    left: '50%',
    top: '48%',
    position: 'absolute',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 2}px`,
    backgroundColor: 'var(--color-gray-200)',
    borderRadius: '12px',
    textAlign: 'center',
  },
  avatar: {
    width: '100%',
    textAlign: 'center',
  },
  text: {
    margin: '20px 0',
    fontWeight: '600',
    fontSize: '16px',
    color: 'var(--color-black)',
  },
  caption: {
    margin: '20px 0 0 0',
    fontSize: '14px',
    color: 'var(--color-gray-900)',
    textAlign: 'center',
  }
});


class Login extends Component {
  state = {
    isLoading: false,
    email: null,
    password: null,
  }

  onLoginhandler = async (credential) => {
    const res = await axios.getOAuthGoogle({token: credential});

    if (res.status === 200) {
      localStorage.setItem('user_session', JSON.stringify(res.data.result));
      localStorage.setItem('token', JSON.stringify(res.data.result.token));
      
      const recruitMeta = await axios.getInterviewColumn(LOCAL_BATCH, localStorage);
      localStorage.setItem('recruitMeta', JSON.stringify(recruitMeta.data.result));
      
      this.props.onhandleLogin()

    } else {
      alert('권한이 없는 계정입니다. 리더십 계정으로 로그인해 주세요.')
    }
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onLoginhandler()
    }
  }

  onChangeInputHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render () {
    const { classes } = this.props
    return (
      <main className={classes.main}>
        <CssBaseline />
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <div className={classes.avatar}>
              <img src={logo} alt='freemed-admin' width="240px"/>
            </div>
            <p className={classes.text}>
              리더십 계정으로 로그인 가능합니다.
            </p>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogin
                text='signin_with'
                width='300px'
                height='60px'
                logo_alignment='left'
                auto_select={false}
                onSuccess={(res) => {
                  this.onLoginhandler(res.credential)
                }}
                onFailure={(err) => {
                  console.log(err);
                }}
              />
            </GoogleOAuthProvider>
          </Paper>
          <p className={classes.caption}>
            PC 이용을 권장합니다.<br/>
            관련 문의: IT팀장
          </p>
        </div>
      </main>
    );
  }
}
Login.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Login);
