import React from 'react';

const Notice = () => {
  return (
    <div style={{margin: '10px 0', padding: '10px', backgroundColor: 'var(--color-gray-100)', borderRadius: '12px', fontSize: '14px', textAlign: 'center'}}>
      <b><span role='img' aria-label='pin'>📌</span> 지원서 다운로드 안내</b><br/>
      <u>지원자 개인정보 보호를 위해 인적사항 및 학력사항 외의 내용은 다운로드할 수 없습니다.</u><br/>
      개인 기기에 저장된 파일은 추적 및 관리가 어려워 보안상의 위험이 발생할 수 있어, 다운로드 기능이 제한되는 점 양해 부탁드립니다.<br/>
      또한, <b style={{color: 'var(--color-freemed-red)'}}>다운로드하신 CSV 파일은 리크루팅 종료 후 반드시 삭제</b>해 주시기 바랍니다.
    </div>
  )
}

export default Notice;
