/* eslint-disable */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import { withStyles } from '@material-ui/core';
import logo from 'static/images/logo.svg'
import logoutUrl from 'lib/service/redirect'
import * as routes from 'lib/service/routes'
import './Sidebar.scss'
import HelpModal from './HelpModal';
import { Button } from 'reactstrap';

const styles = theme => ({
  root: {
    position: 'fixed',
    top: '0px',
    width: '100%',
    height: '46px',
    backgroundColor: 'white',
    zIndex: 100,
    borderBottom: '1px solid #ddd',
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

class Sidebar extends Component {
  state = {
    open: false,
    name: JSON.parse(localStorage.getItem('user_session')).name,
    email: JSON.parse(localStorage.getItem('user_session')).email,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  logoutHandler = () => {
    localStorage.removeItem('session')
    localStorage.removeItem('token')
    window.location.assign(logoutUrl());
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={`${classes.root} Container`}>
        <div>
          <Link className="main-logo" to='/'>
            <img src={logo} alt="FREEMED" width={111} height={17}/> Admin
          </Link>
        </div>
        <div className="ItemBox">
        <div className="ItemContainer">
          <div className="ItemWrapper">
            <ListItem className={'Sidebar__item'}
              component={props => <Link to={routes.MATCH_PATH_DASHBOARD} {...props} />}>
              <ListItemIcon className={'Sidebar__icon'}>
                <DashboardIcon/>
              </ListItemIcon>
              <ListItemText className={`Sidebar__menu`} primary="지원자 현황" />
            </ListItem>
          </div>
        </div>
        <div className="ItemContainer">
          <div className="ItemWrapper">
            <ListItem className={'Sidebar__item'} focused="false" component={props => <Link to={routes.MATCH_PATH_RECRUIT_INFORMATION} {...props} />}>
              <ListItemIcon className={'Sidebar__icon'}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText className={`Sidebar__menu`} primary="지원서 관리" />
            </ListItem>
          </div>
        </div>
        {/*<div className="ItemContainer">*/}
        {/*  <div className="ItemWrapper">*/}
        {/*    <ListItem className={'Sidebar__item'}*/}
        {/*      component={props => <Link to={routes.MATCH_PATH_INTERVIEW} {...props} />}>*/}
        {/*      <ListItemIcon className={'Sidebar__icon'}>*/}
        {/*        <PeopleIcon />*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText className={`Sidebar__menu`} primary="면접관리" />*/}
        {/*    </ListItem>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="ItemContainer">
          <div className="ItemWrapper">
            <ListItem className={'Sidebar__item'}
              component={props => <Link to={routes.MATCH_PATH_QUESTION} {...props} />}>
              <ListItemIcon className={'Sidebar__icon'}>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText className={`Sidebar__menu`} primary="서류 질문" />
            </ListItem>
          </div>
        </div>
        </div>
        <div>
        <div className="LoginContainer">
          <div className="LoginWrapper">
            <div className="LoginInfoWrapper">
              <p className="name"><b>{this.state.name}님</b></p>
            </div>
          </div>
          <HelpModal/>
          <Button outline color="secondary" size="sm" onClick={this.logoutHandler}>로그아웃</Button>
        </div>
      </div>
      </div>
    )
  }
}

export default withStyles(styles)(Sidebar)
