import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InfoIcon from '@material-ui/icons/Info';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import Typography from '@material-ui/core/Typography';

const HelpModal = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button outline color="success" size="sm" style={{display: 'flex', columnGap: '4px', alignItems: 'center'}} onClick={toggle}><InfoIcon/>도움말</Button>
      <Modal size="lg" isOpen={modal} toggle={toggle} style={{width: ''}}>
        <ModalHeader toggle={toggle}>도움말</ModalHeader>
        <ModalBody>
          <Typography variant="subtitle1" style={{display: 'flex', alignItems: 'center', fontWeight: 'bold', columnGap: '4px'}}>
            <DashboardIcon/>지원자 현황
          </Typography>
          <Typography variant="body2" component="p">
            실시간 지원자 현황을 조회할 수 있습니다.
          </Typography>
          <br/>
          <Typography variant="subtitle1" style={{display: 'flex', alignItems: 'center', fontWeight: 'bold',  columnGap: '4px'}}>
            <PeopleIcon/>지원서 관리
          </Typography>
          <img src={require('static/images/guide_01.png')} alt="지원서 도움말" style={{width: '100%'}}/>
          <Typography variant="body2" component="p" align="center">
            원하는 지원서를 눌러서 조회합니다.
          </Typography>
          <img src={require('static/images/guide_02.png')} alt="지원서 도움말" style={{width: '100%'}}/>
          <Typography variant="body2" component="p" align="center">
            지원서 전체 내용을 확인하고 평가/메모란에 자유롭게 남길 수 있습니다.<br/>
            <span role='img' aria-label='warning'>⚠️</span> 모두 메모 용도이며 <b>실제 채점 시 리크루팅 서류 채점 파일에 따로 입력</b>해야 합니다.<br/>
            메모를 작성하면 '평가 상태'에 메모 유무 및 개수가 반영됩니다.
          </Typography>
          <img src={require('static/images/guide_03.png')} alt="지원서 도움말" style={{width: '100%'}}/>
          <Typography variant="body2" component="p" align="center">
            본부/팀/이름/내용으로 검색 범위를 선택 후 <b>검색 키워드 입력 + Enter</b>를 눌러 검색합니다.<br/>
            <span role='img' aria-label='info'>🔍</span> 내용 검색은 특별사항, 경력사항, 질문 답변에 한해 검색됩니다. 
          </Typography>
          <img src={require('static/images/guide_04.png')} alt="지원서 도움말" style={{width: '100%'}}/>
          <Typography variant="body2" component="p" align="center">
            (1) 다운로드할 지원서 체크박스 선택 후 ‘CSV 다운로드’ 버튼을 누릅니다.<br/>
            (2) 다운로드된 ‘지원자.csv’ 파일을 통해 지원자 정보를 확인할 수 있습니다.<br/>
            <span role='img' aria-label='warning'>⚠️</span> 이 외 내용은 지원자 개인정보 보호를 위해 다운로드 할 수 없습니다.
          </Typography>
          <br/>
          <Typography variant="subtitle1" style={{display: 'flex', alignItems: 'center', fontWeight: 'bold',  columnGap: '4px'}}>
            <BarChartIcon/>서류 질문
          </Typography>
          <Typography variant="body2" component="p">
            리크루팅 페이지에 반영된 서류 질문 확인만 가능하며, 질문 수정 시 기술총괄/기술매니저에게 문의 바랍니다.
          </Typography>
        </ModalBody>
        <ModalFooter>
          <Button outline color="secondary" onClick={toggle}>닫기</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default HelpModal
